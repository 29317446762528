import React from "react";
import DateRange from "../../../../components/_base/DateRange";
// import TextAsHTML from "../../../../components/_base/TextAsHTML";
import { EventPublicDetailsResponseType } from "../../../../lib/api";
import useSetKeyIdClass from "../../../../hooks/use-set-key-id-class";

interface EventPublicInfoInterface {
  data: EventPublicDetailsResponseType;
}

const EventPublicInfo: React.FC<EventPublicInfoInterface> = (props) => {
  const { name, startTime, endTime, timeZone, logoTitle, logoUrl } = props.data;
  const key = useSetKeyIdClass();
  const className = key ? `editable-${key}` : "editable-sign-in";
  const classNameTitle = `${className}--left-content-title`;
  return (
    <div className={`container`}>
      <div className={`flex justify-center`}>
        <img
          src={logoUrl}
          alt={logoTitle}
          title={logoTitle}
          className={`img-banner`}
        />
      </div>
      <div
        className={`${classNameTitle}  sm:text-xs md:text-sm lg:text-xl xl:text-2xl text-white  p-3`}
        // className={`${classNameTitle} header-font-size font-bold text-primary line-height-22px header-line truncate-advanced-2 mt-5`}
        title={name}
      >
        {name}
      </div>
      <hr />
      <div>
        <DateRange
          startTime={startTime}
          endTime={endTime}
          timeZone={timeZone}
        />
      </div>

      {/* <div
        className={`${classNameDescription} mt-5 text-sm truncate-advanced-3`}
      >
        <TextAsHTML formattedText={publicDescription} />
      </div> */}
    </div>
  );
};

export default EventPublicInfo;
