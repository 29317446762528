import React from "react";
import OELOGO from "../../Assets/OELogo.png";

const MobileFooter: React.FC = () => {
  return (
    <div className={`flex justify-center items-center m-2`}>
      <p className={`text-white opacity-70 pr-2`}>Powered By </p>
      <div>
        <img src={OELOGO} alt="OE-LOGO" />
      </div>
    </div>
  );
};

export default MobileFooter;
