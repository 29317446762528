import React, { useState } from "react";
import { EventPublicDetailsResponseType } from "../../../lib/api";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import EventPublicInfo from "../LoginWrapper/EventPublicInfo/EventPublicInfo";
import bgImage from "../../../Assets/login-bg-img.jpg";
import AdminCard from "../../../Assets/Admit-Card.png";
import registerSuccessImg from "../../../Assets/register-success.svg";
import mobileRegisterImg from "../../../Assets/mobile-register-img.jpg";
import MobileFooter from "../../shared/MobileFooter";

type Props = {
  data: EventPublicDetailsResponseType;
};

const RegistrationWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [showRegistrationForm, setRegistrationForm] = useState<boolean>(true);
  const [showSubmitMessage, setShowSubmitMessage] = useState<boolean>(false);
  const submitMessage = `Thank you for registering.  Please check your email for details of how to attend the event.`;
  return (
    <>
      <div className="flex justify-between flex-col md:flex-row bg-img-wapper">
        <img src={bgImage} alt="registration-img" className={`home-bgImage`} />
        <EventPublicInfo data={props.data} />
        <div className="form-bg-img">
          <img src={AdminCard} alt="admincard" className={`admit-card`} />
          <div className="w-full register-form mt-6 md:mt-0 p-4 min-w-full md:min-w-0">
            {showSubmitMessage && (
              <div className={`register-message`}>
                <div className={`flex justify-center my-8	`}>
                  <img src={registerSuccessImg} alt="success" />
                </div>
                <span className={`text-white font-size-20px`}>
                  {submitMessage}
                </span>
                {/* <hr className="mt-4 mb-4" /> */}
              </div>
            )}
            {showRegistrationForm && (
              <RegistrationForm
                eventId={props.data.eventId}
                showSubmitMessage={setShowSubmitMessage}
                toggleRegistrationForm={() =>
                  setRegistrationForm(!showRegistrationForm)
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className={`mobile-container`}>
        <img
          src={mobileRegisterImg}
          alt={`mobile-register-bg-img`}
          className={`mobile-img-bg`}
        />
        <div className={`overlay overflow-y-scroll`}>
          <div className={`my-4 sm:w-9/12`}>
            <img
              src={props.data.logoUrl}
              alt={props.data.logoTitle}
              title={props.data.logoTitle}
            />
          </div>
          <>
            {showSubmitMessage && (
              <>
                <div className={`text-white font-size-26px text-center`}>
                  {props.data.name}
                </div>

                <div className={`register-message`}>
                  {/* <div className={`flex justify-center my-8	`}>
                  <img src={registerSuccessImg} alt="success" />
                </div> */}
                  <span className={`text-white font-size-20px`}>
                    {submitMessage}
                  </span>
                </div>
              </>
            )}
            {showRegistrationForm && (
              <RegistrationForm
                eventId={props.data.eventId}
                showSubmitMessage={setShowSubmitMessage}
                toggleRegistrationForm={() =>
                  setRegistrationForm(!showRegistrationForm)
                }
              />
            )}
          </>
          <div className={`mt-2`}>
            <MobileFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationWrapper;
