import React from "react";
import { useHistory } from "react-router";
import menuIcon from "../../Assets/menu-icon.svg";
import { useEventPrivateInfo } from "../../hooks/api/protected/use-event-private-details";
import {
  AGENDA_PATH,
  SESSION_PATH,
  SHOWCASE_PATH,
  SUPPORT_PATH,
  WELCOME_PATH,
} from "../../config/routes/paths";
import {
  IAuthContext,
  useAuth,
} from "../../lib/context-providers/auth-context";
import headPhone from "../../Assets/headphone-icon.png";
import closeIcon from "../../Assets/close-Icon.svg";
import homeIcon from "../../Assets/Home-Icon.svg";
import scheduleIcon from "../../Assets/mySchedule-Icon.svg";
import sessionsIcon from "../../Assets/Sessions-Icon.svg";
import showcasesIcon from "../../Assets/showcases-Icon.svg";
import helpDeskIcon from "../../Assets/helpDesk-Icon.svg";
import signoutIcon from "../../Assets/signout-Icon.svg";
import MobileFooter from "./MobileFooter";

const MobileLayout: React.FC = () => {
  const { data } = useEventPrivateInfo();
  const auth = useAuth() as IAuthContext;
  const history = useHistory();
  const className = "editable-mobile-welcome";
  const classNavList = `${className} flex text-white text-2xl items-center`;

  const handleChange = (path: string) => {
    auth.handleShowSideMenu();
    history.push(path);
  };
  return (
    <>
      {auth.showSideMenu ? (
        <div
          className={`w-full h-full p-6 sidebar flex flex-col justify-around `}
        >
          <div className={`w-5/6 flex justify-start items-center`}>
            <div className={`p-2`} onClick={auth.handleShowSideMenu}>
              <img src={closeIcon} alt="close" />
            </div>
            <div className={`w-9/12 sm:w-2/5 p-2`}>
              <img src={data?.logoUrl} alt="logo" />
            </div>
          </div>
          <div className={`h-65 flex flex-col justify-around	`}>
            <div
              className={`${classNavList}`}
              onClick={() => handleChange(WELCOME_PATH)}
            >
              <div className={`w-1/12 max-w-30 ml-12 mr-4 `}>
                <img src={homeIcon} alt="" className={`w-full`} />
              </div>
              {data?.welcomeLabel}
            </div>
            <div
              className={`${classNavList}`}
              onClick={() => handleChange(AGENDA_PATH)}
            >
              <div className={`w-1/12 max-w-30 ml-12 mr-4`}>
                <img src={scheduleIcon} alt="" className={`w-full`} />
              </div>
              {data?.agendaLabel}
            </div>
            <div
              className={`${classNavList}`}
              onClick={() => handleChange(SESSION_PATH)}
            >
              <div className={`w-1/12 max-w-30 ml-12 mr-4`}>
                <img src={sessionsIcon} alt="" className={`w-full`} />
              </div>
              {data?.sessionLabel}
            </div>
            <div
              className={`${classNavList}`}
              onClick={() => handleChange(SHOWCASE_PATH)}
            >
              <div className={`w-1/12 max-w-30 ml-12 mr-4`}>
                <img src={showcasesIcon} alt="" className={`w-full`} />
              </div>
              {data?.showcaseLabel}
            </div>
            <div
              className={`${classNavList}`}
              onClick={() => handleChange(SUPPORT_PATH)}
            >
              <div className={`w-1/12 max-w-30 ml-12 mr-4`}>
                <img src={helpDeskIcon} alt="" className={`w-full`} />
              </div>
              {data?.supportLabel}
            </div>
            <div
              className={`${classNavList}`}
              onClick={() => {
                auth.handleShowSideMenu();
                auth.signOut();
              }}
            >
              <div className={`w-1/12 max-w-30 ml-12 mr-4`}>
                <img src={signoutIcon} alt="" className={`w-full`} />
              </div>
              Sign Out
            </div>
          </div>
          <div className={` mt-5`}>
            <MobileFooter />
          </div>
        </div>
      ) : (
        <nav
          className={`${className} px-6 pt-6 flex justify-between items-center	`}
        >
          <div className={`w-5/6	flex justify-start items-center`}>
            <div className={`m-2`} onClick={auth.handleShowSideMenu}>
              <img src={menuIcon} alt={`menu`} />
            </div>
            <div className={`w-9/12 sm:w-2/5 p-2`}>
              <img src={data?.logoUrl} alt="logo" />
            </div>
          </div>
          <div className={`m-4`}>
            {window.location.pathname !== "/support" ? (
              <img
                src={headPhone}
                alt="help"
                onClick={() => history.push(SUPPORT_PATH)}
              />
            ) : null}
          </div>
        </nav>
      )}
    </>
  );
};

export default MobileLayout;
