import * as React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import SessionMeetingsList from "./SessionMeetingsList";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import sessionBgImg from "../../../Assets/SESSION-BG-IMG.png";
import NavigationImg from "../../shared/NavigationImg";
import {
  WELCOME_PATH,
  SUPPORT_PATH,
  AGENDA_PATH,
} from "../../../config/routes/paths";
import rollupStandee from "../../../Assets/Rollup standee.svg";
import DateRangeWelcome from "../LoginWrapper/EventPublicInfo/DateRangeWelcome";
import Icon from "../../../components/_base/Icon";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import MobileLayout from "../../shared/MobileLayout";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";

const SessionWrapper: React.FC = () => {
  const { isValidating, data } = useEventPrivateInfo();
  const landingPage = useDefaultRoutePage();
  const history = useHistory();
  const auth = useAuth() as IAuthContext;

  if (data && !data?.sessionEnabled) {
    return <Redirect to={landingPage} />;
  }
  if (!isValidating && data)
    return (
      <>
        <div className="bg-img-wapper">
          <img
            src={sessionBgImg}
            alt={"session-bg-img"}
            className={`home-bgImage`}
          />
          <NavigationImg label="lobby" Path={WELCOME_PATH} leftArrow={true} />
          <NavigationImg
            label={data.supportLabel || ""}
            Path={SUPPORT_PATH}
            leftArrow={false}
          />
          <div className="session-logo-img">
            <img
              src={data.logoUrl}
              alt={data.logoTitle}
              title={data.logoTitle}
            />
          </div>
          <div className={`session-rollup-wapper`}>
            <img src={rollupStandee} alt="rollup" />
            <div className="session-rollup ">
              <img
                src={data.logoUrl}
                alt={data.logoTitle}
                title={data.logoTitle}
                className={`agenda-rollup`}
              />
              <DateRangeWelcome
                startTime={data.startTime}
                endTime={data.endTime}
                timeZone={data.timeZone}
                className="rollup-date-text"
              />
              <p
                className={`session-rollup-text`}
                onClick={() => history.push(AGENDA_PATH)}
              >
                Proceed for {data.agendaLabel}
              </p>
              <div
                className={`fill-current text-white cursor-pointer mt-10px`}
                onClick={() => history.push(AGENDA_PATH)}
              >
                <Icon.LeftArrow width="30px" height="30px" />
              </div>
            </div>
          </div>
          <div className={`session-content`}>
            <SessionMeetingsList event={data} />
          </div>
        </div>
        <div className={`mobile-container`}>
          <img
            src={mobileImg}
            alt={`mobile-welcome-img`}
            className={`mobile-img-bg`}
          />
          <div className={`mobile-layout mb-6 overflow-y-scroll`}>
            <MobileLayout />
            {!auth.showSideMenu && (
              <div className={`px-6 m-height`}>
                <SessionMeetingsList event={data} />
              </div>
            )}
          </div>
        </div>
      </>
      // <>
      //   <EventDetails details={data}/>
      //   <SessionMeetingsList event={data}/>
      // </>
    );
  return null;
};

export default SessionWrapper;
