import React from "react";
import Icon from "../../components/_base/Icon";
import logoutBgImg from "../../../src/Assets/Hanging_Navigation.png";
import { useHistory } from "react-router-dom";

type PropsType = {
  label: string;
  Path: string;
  leftArrow: boolean;
};

const NavigationImg: React.FC<PropsType> = (props: PropsType) => {
  const history = useHistory();

  const handleChange = (path: string) => {
    history.push(path);
  };
  return (
    <div className={`${props.leftArrow ? "Left-nav-img" : "right-nav-img"}`}>
      <img src={logoutBgImg} alt={"nav-img"} />
      <div
        className={`${
          props.leftArrow ? "logout-wapper" : "right-wapper"
        } fill-current text-white`}
        onClick={() => handleChange(props.Path)}
      >
        {props.leftArrow ? (
          <>
            <Icon.LeftArrow width="30px" height="30px" className="mt-10px" />
            <div>{props.label}</div>
          </>
        ) : (
          <>
            <div className={`px-2`} onClick={() => handleChange(props.Path)}>
              {props.label}
            </div>
            <Icon.RightNarrowArrow
              width="30px"
              height="30px"
              className="mt-10px"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NavigationImg;
