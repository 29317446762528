import React, { useState } from "react";
import { useEventPrivateMeetingsList } from "../../../hooks/api/protected/use-event-private-meeting-list";
import {
  EventPrivateDetailsResponseType,
  MeetingDetailsListResponseType,
} from "../../../lib/api";
import MeetingBlock from "./MeetingBlock";
import { getFullDateInLocalFormat } from "../../../lib/helpers/dateHelper";
import "./AgendaDetails.scss";
import Icon from "../../../components/_base/Icon";

// import MobileFooter from "../../shared/MobileFooter";
// import { Button } from "../../../components/_base/Button";
// import { useHistory } from "react-router";
// import { SESSION_PATH } from "../../../config/routes/paths";

interface IProps {
  event: EventPrivateDetailsResponseType;
}

const AgendaDetails: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { data, isValidating } = useEventPrivateMeetingsList(
    String(props.event.eventId)
  ) as { data: MeetingDetailsListResponseType | void; isValidating: boolean };
  const [showPastMeetings, changeShowPastFlag] = useState(false);
  // const history = useHistory();
  const className = "editable-agenda";
  const classNameAgendaWrapper = `${className}--wrapper`;
  const classNameAgendaUpcomingMeeting = `${className}--upcoming-meeting `;
  // const classNameAgendaFooter = `${className}--footer`;

  const renderMeetingList = () => {
    let currentDate = "";
    if (!data || isValidating) {
      return;
    }

    const filteredData = !data.futureMeetings.length
      ? data.pastMeetings
      : showPastMeetings
        ? [...data.pastMeetings, ...data.futureMeetings]
        : data.futureMeetings;

    return filteredData.map((meeting, key) => {
      const meetingBlock = (
        <MeetingBlock
          meeting={meeting}
          key={meeting.meetingId}
          timeZone={props.event.timeZone}
          eventId={`${props.event.eventId}`}
        />
      );
      const formattedStartDate = getFullDateInLocalFormat(
        meeting.startDateTime,
        props.event.timeZone
      );
      if (currentDate !== formattedStartDate) {
        currentDate = formattedStartDate;
        return (
          <div key={key} className={`${classNameAgendaUpcomingMeeting} `}>
            {/* <p className="text-primary font-size-14px font-bold uppercase tracking-widest px-5 pt-8 pb-3">
              {formattedStartDate}
            </p> */}
            {meetingBlock}
          </div>
        );
      } else {
        return meetingBlock;
      }
    });
  };

  const renderDate = () => {
    let currentDate = "";
    if (!data || isValidating) {
      return;
    }

    const filteredData = !data.futureMeetings.length
      ? data.pastMeetings
      : showPastMeetings
        ? [...data.pastMeetings, ...data.futureMeetings]
        : data.futureMeetings;

    return filteredData.map((meeting, key) => {
      const formattedStartDate = getFullDateInLocalFormat(
        meeting.startDateTime,
        props.event.timeZone
      );
      if (currentDate !== formattedStartDate) {
        currentDate = formattedStartDate;
        return (
          <div key={key} className="schedule-date-text">
            <p className="text-white font-size-16px font-bold uppercase tracking-widest ">
              {formattedStartDate}
            </p>
          </div>
        );
      } else {
        return "";
      }
    });
  };

  const toggleElementContent = showPastMeetings ? (
    <>
      <i className="px-1 inline-block not-italic transform -rotate-90">
        &#10095;
      </i>{" "}
      Hide past meetings
    </>
  ) : (
    <>
      <i className="px-3 inline-block not-italic transform rotate-90">
        &#10095;
      </i>{" "}
      Show past meetings
    </>
  );

  const getToggleElement = () => {
    if (!data || !data.pastMeetings.length || !data.futureMeetings.length)
      return;
    return (
      <span
        className="text-sm text-primary self-center cursor-pointer text-right md:whitespace-no-wrap"
        onClick={() => changeShowPastFlag(!showPastMeetings)}
      >
        {toggleElementContent}
      </span>
    );
  };

  const isNoMeetings = (): boolean => {
    return (
      isValidating ||
      !data ||
      (!data.futureMeetings?.length && !data.pastMeetings?.length)
    );
  };

  const getEmptyListMessageBLock = (): JSX.Element => {
    return (
      <>
        <div
          className="font-size-14px italic px-4 py-10 text-white flex justify-center	items-center "
          style={{ margin: "14% 0%" }}
        >
          No meetings have been added to {props.event.agendaLabel} yet.
        </div>
        {/* <hr /> */}
      </>
    );
  };

  // const getGeneralSessionFooterBlock = (): JSX.Element | undefined => {
  //   if (props.event.sessionEnabled) {
  //     return (
  //       <>
  //         <div
  //           className={
  //             classNameAgendaFooter +
  //             "  flex flex-col md:flex-row items-center " +
  //             (!props.event.sessionLinkDescription
  //               ? "justify-center"
  //               : "justify-between")
  //           }
  //         >
  //           <Button
  //             name="sessionMeetings"
  //             type="button"
  //             onClick={() => {
  //               history.push(SESSION_PATH);
  //             }}
  //             label={
  //               <span className="truncate m-auto capitalize max-w-full">
  //                 {" show past " + props.event.sessionLabel}
  //               </span>
  //             }
  //             data-tooltip={"View " + props.event.sessionLabel}
  //             data-tooltip-position="bottom"
  //             className="agenda-footer-btn px-5 py-3  text-center  font-medium text-white"
  //           />
  //           {props.event.sessionLinkDescription && (
  //             <p className="md:ml-10 flex w-full text-left float-none self-center font-size-14px md:pt-0 pt-3">
  //               {props.event.sessionLinkDescription}
  //             </p>
  //           )}
  //         </div>
  //       </>
  //     );
  //   }
  // };

  return (
    <>
      <div
        className={`${classNameAgendaWrapper} md:px-4 py-4 md:w-full max-h-full h-90 w-100 mx-0 px-0 desktop-content`}
      >
        <div className="px-4 flex justify-between w-95 flex-col">
          <p className="font-size-20px mb-2 uppercase text-white	font-bold">
            {props.event.agendaLabel ||
              "welcome mr.smith,below is your schedule"}
          </p>
          <div className={`flex sm:w-full w-1/2 fill-current text-white `}>
            {renderDate()?.length ? (
              <>
                <Icon.Calendar width="30px" height="30px" />
                {renderDate()}
              </>
            ) : null}
          </div>
          {/* {getGeneralSessionFooterBlock()} */}
          {getToggleElement()}
        </div>
        <hr className="hr-line w-93" />
        {isValidating || (
          <>
            <div className="table-list-wapper">
              {isNoMeetings() && getEmptyListMessageBLock()}
              {renderMeetingList()}
            </div>
          </>
        )}
      </div>
      <div className={`mobile-content`}>
        <div className={`px-6`}>
          <p className="font-size-20px mb-2 uppercase text-white font-bold">
            {props.event.agendaLabel ||
              "welcome mr.smith,below is your schedule"}
          </p>
          <div className={`flex w-full fill-current text-white my-4`}>
            {renderDate()?.length ? (
              <>
                <Icon.Calendar width="30px" height="30px" />
                {renderDate()}
              </>
            ) : null}
            {getToggleElement()}
          </div>
        </div>
      </div>
      <div className={`px-6 py-4 mobile-content m-height-25 overflow-y-scroll`}>
        {isValidating || (
          <>
            <div className={`pb-6 break-words list-scroll`}>
              {isNoMeetings() && getEmptyListMessageBLock()}
              {renderMeetingList()}
            </div>
          </>
        )}
        {/* <div className={`absolute m-footer left-0 w-full mt-2`}>
          <MobileFooter />
        </div> */}
      </div>
    </>
  );
};

export default AgendaDetails;
