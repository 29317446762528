import React from "react";
import { Redirect, RouteProps } from "react-router-dom";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import {
  EventSettingsContextType,
  useEventSettings,
} from "../../../lib/context-providers/event-settings-context";
import { EmbedContainer } from "../../../lib/kv-embed";
import NavigationImg from "../../shared/NavigationImg";
import { WELCOME_PATH } from "../../../config/routes/paths";
import MobileLayout from "../../shared/MobileLayout";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";
// import MobileFooter from "../../shared/MobileFooter";

type ShowcaseWrapperProps = {
  location: {
    state: { showcasePagePath: string } | null;
  } & Partial<RouteProps>;
};

const ShowcaseWrapper: React.FC<ShowcaseWrapperProps> = ({
  location,
}: ShowcaseWrapperProps): JSX.Element | null => {
  const pagePath = location?.state?.showcasePagePath ?? "";
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const landingPage = useDefaultRoutePage();
  const auth = useAuth() as IAuthContext;

  if (!eventSettings) return null;

  if (!eventSettings.showcaseEnabled) {
    return <Redirect to={landingPage} />;
  }

  const embedId = eventSettings.showcaseEmbedId || null;

  return (
    <>
      <div className="showcase-wrapper">
        <header className="showcase-header">
          <NavigationImg label="lobby" Path={WELCOME_PATH} leftArrow={true} />
        </header>
        
      </div>
      <div className={`mobile-container`}>
        <img
          src={mobileImg}
          alt={`mobile-welcome-img`}
          className={`mobile-img-bg`}
        />
        <div className={`mobile-layout`}>
          <MobileLayout />
        </div>
      </div>
      <div className="showcase-content">
          <EmbedContainer.Showcase embedId={embedId} pagePath={pagePath} />
        </div>

    </>
  );
};

export default ShowcaseWrapper;
