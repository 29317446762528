import React from "react";
import Modal from "react-modal";
import { EmbedContainer } from "../../../lib/kv-embed";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";

interface IProps {
  welcomeKnovioEmbedId: string;
}

const KnovioThumbnail = (props: IProps): JSX.Element => {
  const { openThumbnail, handleModalOpen } = useAuth() as IAuthContext;

  // const className = `editable-welcome-knovio`;
  // const classNameKnovioWrapper = `${className}--wrapper`;

  if (Modal && Modal.defaultStyles) {
    if (Modal.defaultStyles.content) {
      Modal.defaultStyles.content.border = "none";
      Modal.defaultStyles.content.borderRadius = 0;
      Modal.defaultStyles.content.padding = 0;
      Modal.defaultStyles.content.top = "40px";
      Modal.defaultStyles.content.inset = "0px";
    }
    if (Modal.defaultStyles.overlay) {
      Modal.defaultStyles.overlay.zIndex = 3;
      Modal.defaultStyles.overlay.background = "rgba(0,0,0,0.75)";
    }
  }

  // className={`${className} mx-auto mt-8 bg-black sm-shadow-gray relative cursor-pointer`}
  // style={{ width: '320px', minHeight: '100px' }}
  // onClick={toggleModal}

  return (
    <div>
      <Modal
        isOpen={openThumbnail}
        ariaHideApp={false}
        onRequestClose={handleModalOpen}
      >
        <>
          <div
            className={`${"absolute top-0 right-0 cursor-pointer mr-4 mt-2 text-black cross-icon"}`}
            onClick={handleModalOpen}
          >
            &#x2715;
            {/* <div className={`link-pulse m-0`}></div>
              <p >Exit</p> */}
          </div>
          <EmbedContainer.Knovio embedId={props.welcomeKnovioEmbedId} />
        </>
      </Modal>
      {/* <img
        className='h-full w-full object-contain'
        alt='knovio thumbnail'
        src={`${SECURE_PROTO}://${getKvEnvPrefix()}${KNOVIO_URL}/${THUMBNAIL}/${props.welcomeKnovioEmbedId}`}
      />
      <div className={`${classNameKnovioWrapper} absolute top-0 left-0 w-full h-full flex`}>
        <div className='m-auto rounded-full h-12 w-12 p-left-10px p-top-7px bg-gray-50'>
          <span className='fill-current text-white'>
            <Icon.RightArrow width='35px' height='35px' />
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default KnovioThumbnail;
