import React, { Fragment, useState } from "react";
import { EventPublicDetailsResponseType } from "../../../lib/api";
import LoginForm from "./LoginForm/LoginForm";
import EventPublicInfo from "./EventPublicInfo/EventPublicInfo";
import { AccessCodeReminder } from "./AccessCodeReminder/AccessCodeReminder";
import RegisterBlock from "./RegisterBlock/RegisterBlock";
import {
  IEventCustomSettings,
  useCustomSettings,
} from "../../../hooks/use-custom-settings";
import DateRangeWelcome from "./EventPublicInfo/DateRangeWelcome";
import AdminCard from "../../../Assets/Admit-Card.png";
import loginimg from "../../../Assets/login-bg-img.jpg";
import bgimg from "../../../Assets/LANDING-BG-IMG.png";
import useFormField from "../../../hooks/use-form-field";
import { EMAIL_REGEXP, REQUIRED, SPACE_TRIMMER } from "../../../lib/constants";
import mobilebg from "../../../Assets/mobile-login-bg.jpg";
import pointer from "../../../Assets/Icon-material-touch-app.svg";
import mobileSignImg from "../../../Assets/mobile-sign-img.jpg";
import MobileFooter from "../../shared/MobileFooter";
// import rollupstandee from "../../../Assets/Rollup_standee_login.svg";
// import secondroolup from "../../../Assets/Standee@2x.png";
// import OElogo from "../../../Assets/Powered-by-logo.png";

type Props = {
  signIn(email: string, code: string): Promise<void>;
  data: EventPublicDetailsResponseType;
  showLogin: boolean;
  handleShowLogin(): void;
};
const LoginWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const { logoUrl, logoTitle, name, startTime, endTime, timeZone } = props.data;
  const [showAccessCodeReminder, setShowAccessCodeReminder] =
    useState<boolean>(true);
  const [showSubmitMessage, setShowSubmitMessage] = useState<boolean>(false);

  const emailValidators = [REQUIRED, EMAIL_REGEXP];
  const formatters = [SPACE_TRIMMER];

  const emailField = useFormField("", emailValidators, formatters);

  const submitMessage = `Thank you. If there is an Access Code
          associated with your email address, it will be sent to you by email. Please check your inbox.`;
  const classNameSignIn = "editable-sign-in";
  // const classNameSignInWrapper = `${classNameSignIn}--wrapper`;
  const { registrationEnabled } = useCustomSettings() as IEventCustomSettings;
  return (
    <Fragment>
      {props.showLogin ? (
        <>
          <div className="bg-img-wapper">
            <img src={bgimg} alt="building-img" className={`home-bgImage`} />
            <div className="banner">
              <div className="img-wapper ">
                <img
                  src={logoUrl}
                  alt={logoTitle}
                  title={logoTitle}
                  className={`img-banner`}
                />
              </div>
              <p className="welcomeText">{name}</p>

              <DateRangeWelcome
                startTime={startTime}
                endTime={endTime}
                timeZone={timeZone}
                className="date-text"
              />
              <div className="pulse" onClick={props.handleShowLogin}></div>
            </div>
            <div className="side-banner">
              <div className="side-banner-content-wrap">
                <img
                  src={logoUrl}
                  alt={logoTitle}
                  title={logoTitle}
                  className="side-img"
                />
                <p className="side-banner-text">{name}</p>
              </div>
            </div>
          </div>
          <div className="mobile-container">
            <img src={mobilebg} alt="bg-mobile" className={`mobile-img-bg`} />
            <div className={`justify-around overlay`}>
              <div className={`flex justify-center my-20`}>
                <img
                  src={logoUrl}
                  alt={logoTitle}
                  title={logoTitle}
                  className={`sm:p-8`}
                />
              </div>
              <div className={`text-white  break-words text-center m-4`}>
                <p className={`text-2xl`}>{name}</p>
                <div className={`flex justify-center mt-4`}>
                  <p
                    className="mobile-pulse flex justify-center items-center"
                    onClick={props.handleShowLogin}
                  >
                    <img src={pointer} alt="pointer" />
                  </p>
                </div>
              </div>
              <div className={`mt-20`}>
                <MobileFooter />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={` ${classNameSignIn} bg-img-wapper`}>
            <img src={loginimg} alt="login-img" className={`home-bgImage`} />
            <EventPublicInfo data={props.data} />
            <div className="form-bg-img">
              <img
                alt={"Admin Carrd"}
                src={AdminCard}
                className={`relative admit-card`}
              />
              <div className="form-container">
                {showSubmitMessage && (
                  <>
                    <p className={`text-white`}>{submitMessage}</p>
                    <hr className="mt-4 mb-4" />
                  </>
                )}
                {showAccessCodeReminder && (
                  <LoginForm
                    signIn={props.signIn}
                    errorMsg={props.data.loginErrorMessage}
                    hideSubmitMessage={() => setShowSubmitMessage(false)}
                    showSubmitMessage={showSubmitMessage}
                    showAccessCodeReminder={() =>
                      setShowAccessCodeReminder(!showAccessCodeReminder)
                    }
                    emailField={emailField}
                  />
                )}
                <AccessCodeReminder
                  eventId={props.data.eventId}
                  state={showAccessCodeReminder}
                  showSubmitMessage={setShowSubmitMessage}
                  showAccessCodeReminder={() =>
                    setShowAccessCodeReminder(!showAccessCodeReminder)
                  }
                  emailField={emailField}
                />
                {registrationEnabled && showAccessCodeReminder && (
                  <RegisterBlock />
                )}
              </div>
            </div>
          </div>
          <div className="mobile-container">
            <img
              src={mobileSignImg}
              alt="mobile-sign"
              className={`mobile-img-bg`}
            />
            <div className={`overlay justify-around`}>
              <div className={`my-4 sm:w-9/12	`}>
                <img src={logoUrl} alt={logoTitle} title={logoTitle} />
              </div>
              {showSubmitMessage && (
                <>
                  <p className={`text-white`}>{submitMessage}</p>
                  <hr className="mt-4 mb-4" />
                </>
              )}
              {showAccessCodeReminder && (
                <LoginForm
                  signIn={props.signIn}
                  errorMsg={props.data.loginErrorMessage}
                  hideSubmitMessage={() => setShowSubmitMessage(false)}
                  showSubmitMessage={showSubmitMessage}
                  showAccessCodeReminder={() =>
                    setShowAccessCodeReminder(!showAccessCodeReminder)
                  }
                  emailField={emailField}
                />
              )}
              <AccessCodeReminder
                eventId={props.data.eventId}
                state={showAccessCodeReminder}
                showSubmitMessage={setShowSubmitMessage}
                showAccessCodeReminder={() =>
                  setShowAccessCodeReminder(!showAccessCodeReminder)
                }
                emailField={emailField}
              />
              <div className={`flex justify-center mt-8`}>
                {registrationEnabled && showAccessCodeReminder && (
                  <RegisterBlock />
                )}
              </div>
              <div className={`mt-2`}>
                <MobileFooter />
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default LoginWrapper;
