import React from "react";
import AgendaDetails from "./AgendaDetails";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import { Redirect } from "react-router-dom";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import scheduleImg from "../../../Assets/SCHEDULE-BG-IMG.png";
import rollUpBannerImg from "../../../Assets/RollUpBanner.png";
import DateRangeWelcome from "../LoginWrapper/EventPublicInfo/DateRangeWelcome";
import NavigationImg from "../../shared/NavigationImg";
import {
  WELCOME_PATH,
  SUPPORT_PATH,
  SESSION_PATH,
} from "../../../config/routes/paths";
import Icon from "../../../components/_base/Icon";
import { useHistory } from "react-router-dom";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import MobileLayout from "../../shared/MobileLayout";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";

const AgendaWrapper = (): JSX.Element | null => {
  const { data, isValidating } = useEventPrivateInfo();
  const landingPage = useDefaultRoutePage();
  const history = useHistory();
  const auth = useAuth() as IAuthContext;

  if (!data) return null;

  if (!data.agendaEnabled) {
    return <Redirect to={landingPage} />;
  }

  if (!isValidating && data)
    return (
      <>
        <div className="bg-img-wapper">
          <img src={scheduleImg} alt={"bgimg"} className={"home-bgImage"} />
          <div className="desk-img">
            <img
              src={data.logoUrl}
              alt={data.logoTitle}
              title={data.logoTitle}
            />
          </div>
          <NavigationImg label="lobby" Path={WELCOME_PATH} leftArrow={true} />
          <NavigationImg
            label={data.supportLabel || ""}
            Path={SUPPORT_PATH}
            leftArrow={false}
          />
          <div className="rollup-banner">
            <img src={rollUpBannerImg} alt={"rollupBanner"} />
            <div className="text-center	p-5 text-white rollup-content">
              <img
                src={data.logoUrl}
                alt={data.logoTitle}
                title={data.logoTitle}
                className={`agenda-rollup`}
              />
              <DateRangeWelcome
                startTime={data.startTime}
                endTime={data.endTime}
                timeZone={data.timeZone}
                className="rollup-date-text"
              />
              <p
                className={`proceed-text`}
                onClick={() => history.push(SESSION_PATH)}
              >
                Proceed for {data.sessionLabel}
              </p>
              <div
                className={`fill-current text-white cursor-pointer mt-10px`}
                onClick={() => history.push(SESSION_PATH)}
              >
                <Icon.RightNarrowArrow width="30px" height="30px" />
              </div>
            </div>
          </div>
          <div className="table-wapper">
            <AgendaDetails event={data} />
          </div>
        </div>
        <div className={`mobile-container`}>
          <img
            src={mobileImg}
            alt={`mobile-welcome-img`}
            className={`mobile-img-bg`}
          />
          <div className={`mobile-layout overflow-y-scroll`}>
            <MobileLayout />
            {!auth.showSideMenu && <AgendaDetails event={data} />}
          </div>
        </div>

        {/* <EventDetails details={data}/> */}
      </>
    );
  return null;
};

export default AgendaWrapper;
