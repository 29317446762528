import React from "react";
import { Redirect } from "react-router-dom";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import supportBgImg from "../../../Assets/support-bg.jpg";
import NavigationImg from "../../shared/NavigationImg";
import { WELCOME_PATH, SESSION_PATH } from "../../../config/routes/paths";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import MobileLayout from "../../shared/MobileLayout";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";
import headPhone from "../../../Assets/help-support.svg";
import Icon from "../../../components/_base/Icon";
// import MobileFooter from "../../shared/MobileFooter";


const SupportPageWrapper: React.FC = (): JSX.Element | null => {
  const { data } = useEventPrivateInfo();
  const auth = useAuth() as IAuthContext;

  const landingPage = useDefaultRoutePage();

  const regex = /(<([^>]+)>)/gi;
  const link = data && data.supportInformation?.replace(regex, "");

  if (data && !data?.supportEnabled) {
    return <Redirect to={landingPage} />;
  }

  if (data?.supportInformation)
    return (
      <>
        <div className="support-page-wrapper bg-img-wapper">
          <img
            src={supportBgImg}
            alt={"support-bg-img"}
            className={`home-bgImage`}
          />
          <NavigationImg label="lobby" Path={WELCOME_PATH} leftArrow={true} />
          <NavigationImg
            label={data.sessionLabel || ""}
            Path={SESSION_PATH}
            leftArrow={false}
          />
          <div className="support-logo-img">
            <img
              src={data.logoUrl}
              alt={data.logoTitle}
              title={data.logoTitle}
            />
          </div>
          <div className="support-text">
            <p className={` md:text-4xl 2xl:text-5xl`}>{data.supportLabel}</p>
          </div>
          <a href={link} target={"_blank"} rel="noopener noreferrer">
            <div className="pulse place-pulse"> </div>
          </a>
        </div>

        <div className={`mobile-container`}>
          <img
            src={mobileImg}
            alt={`mobile-welcome-img`}
            className={`mobile-img-bg`}
          />
          <div className={`mobile-layout`}>
            <MobileLayout />
            {!auth.showSideMenu && (
              <div className={`px-6 py-4 m-height`}>
                <p className={`text-white font-size-24px mt-4`}>
                  {data.supportLabel}
                </p>
                <p className={`text-white my-8 capitalize`}>
                  Connect to live operator today
                </p>
                <div>
                  <div className={`flex items-start	 mb-12`}>
                    <img src={headPhone} alt="" className={`mx-2`} />
                    <div className={`text-white ml-4`}>
                      <p>If you are having trouble,</p>
                      <p className={`font-size-15px font-bold`}>
                        Please Join Us At Our Virtual Support Desk.
                      </p>
                    </div>
                  </div>
                  <div className={`flex items-start	 mb-12`}>
                    <div className={`fill-current text-white opacity-70	`}>
                      <Icon.Globe width="40px" height="40px" />
                    </div>
                    <div className={`text-white ml-4`}>
                      <p>
                        If you are a Presenter or Attendee who wishes to test a
                        connection, Visit the
                        <p>
                          <a
                            href={link}
                            target={"_blank"}
                            rel="noopener noreferrer"
                            className={`underline font-bold`}
                          >
                            OpenExchange Test Line.
                          </a>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className={`absolute w-full m-footer left-0	text-center`}>
                  <MobileFooter />
                </div> */}
              </div>
            )}
          </div>
        </div>

        {/* <EventDetails details={data}/>
      <SupportPageInfo
        supportLabel={data?.supportLabel || 'Support'}
        supportInformation={data?.supportInformation || ''}
      /> */}
      </>
    );
  return null;
};

export default SupportPageWrapper;
