import React from 'react';

type ErrorBlockProps = {
  errorMessage: string
}

const ErrorBlock:React.FC<ErrorBlockProps> = ({ errorMessage }) => {
  return(<div className='error-msg  width-full bg-white py-2 py-40px font-medium font-size-13px break-words bg-opacity-0 link' dangerouslySetInnerHTML={{ __html: errorMessage }} />);
};

export default ErrorBlock;
