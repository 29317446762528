import React from "react";
import { MeetingOrganizationDetailsListType } from "../../lib/api";

interface IProps {
  blockName: string;
  organizations: MeetingOrganizationDetailsListType;
}

const OrganizationsList: React.FC<IProps> = (props: IProps) => {
  if (!props.organizations.length) return null;
  return (
    <div className="pt-2">
      <h3 className="text-primary font-size-12px px-0 sm:pl-8 sm:pr-12">
        {props.blockName}
      </h3>
      {props.organizations.map((org, key) => {
        return (
          <div className="pb-5 px-0 lg:flex sm:pr-12" key={key}>
            {/* <p className='font-medium font-size-18px pb-1'>{org.name}</p> */}
            {!!org.attendees &&
              org.attendees.map((att, key) => (
                <div className={`flex w-full items-center mt-2 lg:mt-0`} key={key}>
                  <div
                    className={`w-8 h-8 rounded-full text-xs flex justify-center items-center bg-avatar`}
                  >
                    <p>{`${att.firstName.charAt(0)}${att.lastName.charAt(
                      0
                    )}`}</p>
                  </div>
                  <div className={`pl-2`}>
                    <p className="text-sm text-white">
                      {`${att.firstName} ${att.lastName}`}
                    </p>
                    <p className="font-size-10px text-white opacity-50">
                      {att.title}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default OrganizationsList;
