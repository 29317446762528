import React from "react";
import { responseInterface } from "swr";
import { HTTP_STATUS, ResponseError } from "../../../config/api";
import { useMeetingPrivateDetails } from "../../../hooks/api/protected/use-meeting-private-details";
import useConnectToPrivateMeetingMethod from "../../../hooks/use-connect-to-private-meeting-method";
import { useMeetingId } from "../../../hooks/use-meeting-id";
import { useEventId } from "../../../hooks/use-event-id";
import { PrivateMeetingDetailsResponseType } from "../../../lib/api";
import {
  EventSettingsContextType,
  useEventSettings,
} from "../../../lib/context-providers/event-settings-context";
import JoinButton from "../../shared/JoinButton";
import { Redirect, useHistory } from "react-router-dom";
import useConnectMeetingDisabled from "../../../hooks/use-connect-meeting-disabled";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";

import meetingBgImg from "../../../Assets/SESSION-BG-IMG.png";
import NavigationImg from "../../shared/NavigationImg";
import {
  AGENDA_PATH,
  SUPPORT_PATH,
  SESSION_PATH,
} from "../../../config/routes/paths";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import OrganizationsList from "../../shared/OrganizationsList";
import {
  getFullDateInLocalFormat,
  getTimeInLocalFormat,
} from "../../../lib/helpers/dateHelper";
import { getConfigByPath } from "../../../hooks/use-navbar-items";
import Assigmentimg from "../../../Assets/assignment_black.svg";
import calendar from "../../../Assets/Icon-calendar.svg";
import clock from "../../../Assets/Icon md-time.svg";
import { Button } from "../../../components/_base/Button";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import Icon from "../../../components/_base/Icon";
// import MobileFooter from "../../shared/MobileFooter";
import text from "../../../Assets/Group 68.svg";

const MeetingWrapper: React.FC = (props) => {
  const { data } = useEventPrivateInfo();
  const meetingId = useMeetingId();
  const eventId = useEventId();
  const history = useHistory();
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const landingPage = useDefaultRoutePage();
  const { data: meetingDetails, error } = useMeetingPrivateDetails(
    eventId,
    meetingId
  ) as responseInterface<PrivateMeetingDetailsResponseType, ResponseError>;
  const isConnectBtnDisabled = useConnectMeetingDisabled(
    eventSettings,
    meetingDetails?.startDateTime
  );
  const path = window.location.pathname.startsWith(AGENDA_PATH)
    ? AGENDA_PATH
    : SESSION_PATH;
  const { label } = (path && data && getConfigByPath(path, data)) || {
    label: (data && data.agendaLabel) || "Agenda",
  };

  const handleChange = () => {
    history.push(`${window.location.pathname}/video`);
  };

  const handleGoBack = (path: string) => {
    history.push(path);
  };

  if (
    error &&
    (error.status === HTTP_STATUS.FORBIDDEN ||
      error.status === HTTP_STATUS.NOT_FOUND)
  ) {
    return <Redirect to={landingPage} />;
  }

  if (eventSettings && meetingDetails && data) {
    return (
      <>
        <div className={`bg-img-wapper`}>
          <img
            src={meetingBgImg}
            alt="meeting-bg-img"
            className={`home-bgImage`}
          />
          <NavigationImg label={label || ""} Path={path} leftArrow={true} />
          <NavigationImg
            label={data.supportLabel || ""}
            Path={SUPPORT_PATH}
            leftArrow={false}
          />
          <div className={`meeting-logo-img`}>
            <img
              src={data.logoUrl}
              alt={data.logoTitle}
              title={data.logoTitle}
            />
          </div>
          <div className={`meeting-content`}>
            <div className={`w-full flex justify-between items-center	`}>
              <img
                src={data.logoUrl}
                alt={data.logoTitle}
                title={data.logoTitle}
                className={`w-3/12`}
              />
              <div>
                {meetingDetails.embedCodeId ? (
                  <Button
                    name="meetingItem"
                    data-meeting-id={meetingId}
                    type="button"
                    label={"Join"}
                    className={`w-full rounded bg-white border font-medium border-gray-400 text-primary button`}
                    onClick={handleChange}
                  />
                ) : (
                  <JoinButton
                    isDisabled={isConnectBtnDisabled}
                    meetingDetails={meetingDetails}
                    useConnectToMeetingMethod={useConnectToPrivateMeetingMethod}
                    className={`w-full rounded bg-white border font-medium border-gray-400 text-primary button`}
                  />
                )}
              </div>
            </div>
            <div className="w-full mt-1">
              {(meetingDetails.topic || meetingDetails.notes) && (
                <div className="pt-2 items-center fill-current text-gray-600 flex px-0 sm:pr-12">
                  <img
                    src={Assigmentimg}
                    alt="Assigment"
                    className="meeting-Assigment"
                  />
                  <p
                    className={`text-white font-bold md:text-base lg:text-lg xl:text-xl	`}
                  >
                    {meetingDetails.topic}
                  </p>
                  <p className="text-sm">{meetingDetails.notes}</p>
                </div>
              )}
              <div className="pt-2 fill-current text-gray-600 flex text-white xs:pt-8 text-center sm:pr-12">
                <img
                  src={calendar}
                  alt="Calender"
                  className="meeting-calender"
                />

                <p
                  className={`text-white font-size-14px uppercase tracking-widest`}
                >
                  {getFullDateInLocalFormat(
                    meetingDetails.startDateTime,
                    eventSettings.timeZone
                  )}
                </p>
              </div>
              <div
                className={`pt-4 fill-current text-gray-600 flex text-white xs:pt-8 pb-1 text-center pt-1 sm:pr-12`}
              >
                <img src={clock} alt="Clock" className="meeting-clock" />
                <p
                  className={`text-white font-size-15px uppercase tracking-widest`}
                >
                  {getTimeInLocalFormat(
                    meetingDetails.startDateTime,
                    eventSettings.timeZone
                  )}
                </p>
              </div>
              <p
                className={`capitalize text-white mt-4 cursor-pointer`}
                onClick={() =>
                  history.push(`${window.location.pathname}/speakers`)
                }
              >
                Speakers
              </p>
              <div>
                {meetingDetails.presenterOrganizations && (
                  <OrganizationsList
                    blockName=""
                    organizations={meetingDetails.presenterOrganizations}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`mobile-container`}>
          <img
            src={mobileImg}
            alt={`mobile-welcome-img`}
            className={`mobile-img-bg`}
          />
          <div className={`mobile-layout p-6 `}>
            <div className={`fill-current text-white flex items-baseline `}>
              <div onClick={() => handleGoBack(path)}>
                <Icon.LeftArrow width="40px" height="40px" />
              </div>
              <p className={`text-white font-size-24px`}>
                {meetingDetails.topic}
              </p>
            </div>
            {(meetingDetails.topic || meetingDetails.notes) && (
              <div className="mt-4 items-center fill-current items-center text-gray-600 flex px-0 sm:pr-12">
                <img
                  src={Assigmentimg}
                  alt="Assigment"
                  className="meeting-Assigment"
                />
                <p
                  className={`text-white font-bold md:text-base lg:text-lg xl:text-xl	`}
                >
                  {meetingDetails.topic}
                </p>
                <p className="text-sm">{meetingDetails.notes}</p>
              </div>
            )}
            <div className="mt-4 fill-current text-gray-600 items-center flex text-white xs:pt-8 text-center sm:pr-12">
              <img src={calendar} alt="Calender" className="meeting-calender" />

              <p
                className={`text-white font-size-16px uppercase tracking-widest`}
              >
                {getFullDateInLocalFormat(
                  meetingDetails.startDateTime,
                  eventSettings.timeZone
                )}
              </p>
            </div>
            <div
              className={`mt-4 fill-current text-gray-600 flex text-white xs:pt-8 pb-1 items-center pt-1 sm:pr-12`}
            >
              <img src={clock} alt="Clock" className="meeting-clock" />
              <p
                className={`text-white font-size-16px uppercase tracking-widest`}
              >
                {getTimeInLocalFormat(
                  meetingDetails.startDateTime,
                  eventSettings.timeZone
                )}
              </p>
            </div>
            <div
              className={`mt-4 fill-current text-gray-600 flex text-white items-center`}
            >
              <img
                src={text}
                alt="Clock"
                className="meeting-clock opacity-50"
              />
              <p
                className={`text-white font-size-16px bg-primary p-2 rounded-full`}
              >
                {meetingDetails.type}
              </p>
            </div>

            <div className={`text-white font-size-16px mt-4`}>
              <p
                onClick={() =>
                  history.push(`${window.location.pathname}/speakers`)
                }
              >
                Speakers
              </p>
            </div>
            <div>
              {meetingDetails.presenterOrganizations && (
                <OrganizationsList
                  blockName=""
                  organizations={meetingDetails.presenterOrganizations}
                />
              )}
            </div>
            <div className={``}>
              {meetingDetails.embedCodeId ? (
                <Button
                  name="meetingItem"
                  data-meeting-id={meetingId}
                  type="button"
                  label={"Join"}
                  className={`w-full font-size-16px rounded bg-primary border font-medium border-gray-400 text-white h-12`}
                  onClick={handleChange}
                />
              ) : (
                <JoinButton
                  isDisabled={isConnectBtnDisabled}
                  meetingDetails={meetingDetails}
                  useConnectToMeetingMethod={useConnectToPrivateMeetingMethod}
                  className={`w-full font-size-16px rounded bg-primary border font-medium border-gray-400 text-white h-12`}
                />
              )}
            </div>
          </div>
        </div>

        {/* <EventDetails details={eventSettings} />
        <MeetingDetails
          meetingDetails={meetingDetails}
          showTopNavBtns={true}
          timeZone={eventSettings.timeZone}
          agendaLabel={eventSettings.agendaLabel}
          showDetailsAllowed={!isConnectBtnDisabled}
        >
          {console.log("eventSettings",eventSettings)}
          <JoinButton
            isDisabled={isConnectBtnDisabled}
            meetingDetails={meetingDetails}
            useConnectToMeetingMethod={useConnectToPrivateMeetingMethod}
          />
        </MeetingDetails> */}
      </>
    );
  }
  return null;
};

export default MeetingWrapper;
