import React, { useEffect, useState } from "react";
import auditorium from "../../../Assets/auditorium.jpg";
import { Redirect, useHistory } from "react-router-dom";
import { useMeetingPrivateDetails } from "../../../hooks/api/protected/use-meeting-private-details";
import { responseInterface } from "swr";
import { PrivateMeetingDetailsResponseType } from "../../../lib/api";
import { HTTP_STATUS, ResponseError } from "../../../config/api";
import { useMeetingId } from "../../../hooks/use-meeting-id";
import { useEventId } from "../../../hooks/use-event-id";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import {
  EventSettingsContextType,
  useEventSettings,
} from "../../../lib/context-providers/event-settings-context";
import TrackingParamsMapper from "../../../lib/kv-embed/KvEmbedTrackingDataMapper";
import { CurrentUserResponseType } from "../../../lib/api";
import { getKvContentKnovioUrl } from "../../../lib/api/utils";
import { useCurrentUserInfo } from "../../../hooks/api/protected/use-current-user-info";

const Auditorium: React.FC = (props) => {
  const { data } = useEventPrivateInfo();
  const meetingId = useMeetingId();
  const eventId = useEventId();
  const { data: meetingDetails, error } = useMeetingPrivateDetails(
    eventId,
    meetingId
  ) as responseInterface<PrivateMeetingDetailsResponseType, ResponseError>;
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const [dimlight, setDimLight] = useState(true);

  const landingPage = useDefaultRoutePage();
  const datas = useCurrentUserInfo();

  const history = useHistory();
  const handleExit = () => {
    history.goBack();
  };

  const time = 1000;

  useEffect(() => {
    setTimeout(() => {
      setDimLight(false);
    }, time);
  }, []);

  const handleUrl = () => {
    if (meetingDetails) {
      if (meetingDetails.connectionDefined) {
        if (meetingDetails.embedCodeId) {
          const trackingParams = TrackingParamsMapper.mapToParams(
            datas.data as CurrentUserResponseType
          );
          const queryParams = Object.entries(trackingParams)
            .map(([key, val]) => `${key}=${val}`)
            .join("&");
          const url = `${getKvContentKnovioUrl(
            meetingDetails.embedCodeId
          )}?${queryParams}`;

          return url;
        }

        return meetingDetails.connectionLink;
      }
      return meetingDetails.attendeeLink;
    }
  };

  if (
    error &&
    (error.status === HTTP_STATUS.FORBIDDEN ||
      error.status === HTTP_STATUS.NOT_FOUND)
  ) {
    return <Redirect to={landingPage} />;
  }
  if (eventSettings && meetingDetails && data) {
    return (
      <>
        <div className={`bg-img-wapper ${dimlight ? "" : "auditoriumLight"} `}>
          <img
            src={auditorium}
            alt="Auditorium"
            className={`home-bgImage ${dimlight ? "" : "opacity-50"} `}
          />
          <div className={`screen p-1`}>
            <iframe
              src={handleUrl()}
              title="Auditorium"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div
            className={`${
              dimlight
                ? "exit-place hidden"
                : "exit-place text-white cursor-pointer"
            }`}
            onClick={handleExit}
          >
            <div className={`pulse`}></div>
            <p>Exit</p>
          </div>
        </div>
        <div className={`mobile-container`}>
          <div className={`mobile-layout overflow-y-scroll`}>
            <iframe
              src={handleUrl()}
              title="Auditorium"
              width="100%"
              height="100%"
            ></iframe>
            <div
              className={`${"absolute top-0 right-0 mr-4 mt-2 text-black cross-icon"}`}
              onClick={handleExit}
            >
              &#x2715;
              {/* <div className={`link-pulse m-0`}></div>
              <p >Exit</p> */}
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};
export default Auditorium;
