import React, { FormEvent } from "react";
import Form from "../../../../components/_base/Form";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import { requestAccessCode } from "../../../../lib/api/public";

interface IProps {
  state: boolean;
  showAccessCodeReminder: () => void;
  showSubmitMessage: (v: boolean) => void;
  eventId: number;
  emailField: {
    value: string;
    onChange(e?: React.ChangeEvent<HTMLInputElement> | string): void;
    isValid: boolean;
  };
}

export const AccessCodeReminder = (props: IProps): JSX.Element => {
  const { emailField } = props;
  const isValid = [emailField.isValid].every(Boolean);

  const requestAccessCodeHandle = (e: FormEvent): void => {
    e.preventDefault();

    requestAccessCode(props.eventId.toString(), emailField.value);

    props.showSubmitMessage(true);
    props.showAccessCodeReminder();
  };
  const className = "editable-sign-in";
  const classNameSignInAccessCode = props.state
    ? `${className}--access-code`
    : `${className}--back-to-sign-in`;
  const classNameSignInSubmitButton = `${className}--access-submit-button`;

  return (
    <>
      <small
        className={`${classNameSignInAccessCode} font-bold text-primary w-full`}
      >
        {!props.state && (
          // <div
          //   className='flex justify-center text-white font-size-13px font-medium'
          //   onClick={()=> props.showSubmitMessage(false)}
          // >
          //   Request Access Code
          // </div> :
          <div>
            {/* <span className="text-white">&#x25C0;</span> */}
            <span className="font-size-35px uppercase text-white font-bold">
              Sign In
            </span>
          </div>
        )}
      </small>

      {!props.state && (
        <Form onSubmit={requestAccessCodeHandle} name="accessCodeForm">
          <div className="text-xl mt-4 text-white font-size-18px break-words whitespace-pre-wrap">
            Please enter your email address below to request your Access Code
          </div>
          {/* <small className="text-white">Please enter your email address.</small> */}
          <div className="mt-8">
            <Input.Email
              id="email"
              name="email"
              autoFocus
              placeholder="name@email.com"
              label="Email Address"
              {...emailField}
            />
          </div>
          <div
            className={`${classNameSignInSubmitButton} flex justify-end	 items-center mt-8`}
          >
            <div
              className={`mr-8 cursor-pointer`}
              onClick={() => {
                props.showAccessCodeReminder();
                emailField.onChange("");
              }}
            >
              <p className={`text-white font-size-13px`}>
                Sign in with Access Code
              </p>
            </div>
            <div>
              <Button.Submit
                name="requestAccessCode"
                label="Continue"
                size="big"
                disabled={!isValid}
                className="min-w-full px-5 py-3 rounded bg-primary text-white font-bold tracking-wider"
              />
            </div>
          </div>
        </Form>
      )}
    </>
  );
};
