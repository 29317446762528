import React from "react";
import { responseInterface } from "swr";
import { Redirect, useHistory } from "react-router-dom";
import { HTTP_STATUS, ResponseError } from "../../../config/api";
import bgImage from "../../../Assets/SPEAKER-BG-IMG.png";
import NavigationImg from "../../shared/NavigationImg";
import { useMeetingId } from "../../../hooks/use-meeting-id";
import { useEventId } from "../../../hooks/use-event-id";
import { PrivateMeetingDetailsResponseType } from "../../../lib/api";
import { Button } from "../../../components/_base/Button";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import MobileLayout from "../../shared/MobileLayout";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";
import {
  EventSettingsContextType,
  useEventSettings,
} from "../../../lib/context-providers/event-settings-context";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import { useMeetingPrivateDetails } from "../../../hooks/api/protected/use-meeting-private-details";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import Icon from "../../../components/_base/Icon";
import {
  AGENDA_PATH,
  SESSION_PATH,
} from "../../../config/routes/paths";

const Speakers: React.FC = () => {
  const { data } = useEventPrivateInfo();
  const auth = useAuth() as IAuthContext;
  const meetingId = useMeetingId();
  const eventId = useEventId();
  const eventSettings = useEventSettings() as EventSettingsContextType;
  const landingPage = useDefaultRoutePage();
  const { data: meetingDetails, error } = useMeetingPrivateDetails(
    eventId,
    meetingId
  ) as responseInterface<PrivateMeetingDetailsResponseType, ResponseError>;
  const history = useHistory();
  const path = window.location.pathname.startsWith(AGENDA_PATH)
    ? AGENDA_PATH
    : SESSION_PATH;

  if (
    error &&
    (error.status === HTTP_STATUS.FORBIDDEN ||
      error.status === HTTP_STATUS.NOT_FOUND)
  ) {
    return <Redirect to={landingPage} />;
  }
  const handleGoBack = (path: string) => {
    history.push(path);
  };

  if (eventSettings && meetingDetails && data) {
    return (
      <>
        <div className={`bg-img-wapper`}>
          <img src={bgImage} alt={`speaker-bgimg`} className={`home-bgImage`} />
          <NavigationImg label={"Meeting Details"} Path={`/agenda/${eventId}/meeting/${meetingId}`} leftArrow={true} />
          <NavigationImg
            label={"Help Desk"}
            Path={"/support"}
            leftArrow={false}
          />
          <div className={`speaker-board`}>
            <div className={`flex justify-between items-center`}>
              <p className={`text-white tracking-wider font-bold`}>
                List of Speakers
              </p>
              <Button
                size={"auto"}
                data-meeting-id={meetingId}
                name="Meeting Details"
                label="Meeting Details"
                type="button"
                className={`text-white px-3 py-2 speaker-button`}
                onClick={() => history.goBack()}
              />
            </div>
            <div>
              {meetingDetails.presenterOrganizations?.length &&
                meetingDetails.presenterOrganizations[0].attendees?.map(
                  (att, key) => (
                    <div className={` w-2/5	flex my-4`} key={key}>
                      <div
                        className={`w-8 h-8 rounded-full text-xs flex justify-center items-center bg-avatar`}
                      >
                        <p>{`${att.firstName.charAt(0)}${att.lastName.charAt(
                          0
                        )}`}</p>
                      </div>
                      <div className={`px-4`}>
                        <p className="text-sm text-white">
                          {`${att.firstName} ${att.lastName}`}
                        </p>
                        <p className="font-size-10px text-white opacity-50">
                          {att.title}
                        </p>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </div>
        <div className={`mobile-container`}>
          <img
            src={mobileImg}
            alt={`mobile-welcome-img`}
            className={`mobile-img-bg`}
          />
          <div className={`mobile-layout overflow-y-scroll`}>
          <div className={`px-6 mt-4 fill-current text-white flex items-baseline `}>
              <div onClick={() => handleGoBack(path)}>
                <Icon.LeftArrow width="40px" height="40px" />
              </div>
              <p className={`text-white font-size-24px`}>
                Meeting Details
              </p>
            </div>
            {/* <MobileLayout /> */}
            {!auth.showSideMenu && (
              <div className={`px-6 mt-4`}>
                <p className={`text-white tracking-wider font-bold`}>
                  List of Speakers
                </p>
                <div>
                  {meetingDetails.presenterOrganizations?.length &&
                    meetingDetails.presenterOrganizations[0].attendees?.map(
                      (att, key) => (
                        <div className={`flex my-4`} key={key}>
                          <div
                            className={`w-8 h-8 rounded-full text-xs flex justify-center items-center bg-avatar`}
                          >
                            <p>{`${att.firstName.charAt(
                              0
                            )}${att.lastName.charAt(0)}`}</p>
                          </div>
                          <div className={`pl-4`}>
                            <p className="text-sm text-white">
                              {`${att.firstName} ${att.lastName}`}
                            </p>
                            <p className="font-size-10px text-white opacity-50">
                              {att.title}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default Speakers;
