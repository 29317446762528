import React, { FormEvent } from "react";
import Input from "../../../../components/input";
import Form from "../../../../components/_base/Form";
import Button from "../../../../components/button";
import useFormField from "../../../../hooks/use-form-field";
import ErrorBlock from "../../../../components/_base/ErrorBlock";
import {
  IAuthContext,
  useAuth,
} from "../../../../lib/context-providers/auth-context";
import { useCustomSettings } from "../../../../hooks/use-custom-settings";
import { MIN6CHAR, REQUIRED, SPACE_TRIMMER } from "../../../../lib/constants";

type Props = {
  signIn(email: string, code: string): Promise<void>;
  errorMsg?: string;
  hideSubmitMessage: () => void;
  showSubmitMessage: boolean;
  showAccessCodeReminder: () => void;
  emailField: {
    value: string;
    onChange(e?: React.ChangeEvent<HTMLInputElement> | string): void;
    isValid: boolean;
  };
};

const accessCodeValidators = [REQUIRED, MIN6CHAR];
const formatters = [SPACE_TRIMMER];

const LoginForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { loginErrorMessage, loginInstructions } = useCustomSettings();
  const { emailField } = props;

  const accessCodeFiled = useFormField("", accessCodeValidators, formatters);
  const { authError, resetAuthError } = useAuth() as IAuthContext;
  const dismissErrors = () => resetAuthError();
  const isValid = [emailField.isValid, accessCodeFiled.isValid].every(Boolean);

  const signInHandle = (e: FormEvent) => {
    e.preventDefault();
    return props.signIn(emailField.value, accessCodeFiled.value);
  };
  const className = "editable-sign-in";
  const classNameSignInTitle = `${className}--title`;
  const classNameSignInSubmitButton = `${className}--submit-button`;

  const signInWithDifferentEmail = () => {
    emailField.onChange("");
    props.showAccessCodeReminder();
    props.hideSubmitMessage();
  };

  return (
    <Form onSubmit={signInHandle} name="loginForm">
      <div
        className={`${classNameSignInTitle} font-size-35px sm:text-2xl	md:text-3xl	lg:text-4xl text-white leading-10 h-10 admit-text uppercase font-bold `}
      >
        Sign In
      </div>
      {!props.showSubmitMessage && loginInstructions && !authError && (
        <div
          className="mt-15px text-white break-words"
          dangerouslySetInnerHTML={{ __html: loginInstructions || "" }}
        />
      )}
      <div className="mt-20px">
        <Input.Email
          id="email"
          name="email"
          autoFocus
          placeholder="name@email.com"
          label="Email Address"
          error={!!authError}
          onFocus={dismissErrors}
          disabled={props.showSubmitMessage && !!emailField.value}
          {...emailField}
        />
      </div>
      <div className="mt-20px">
        <Input.Password
          id="accessCode"
          name="accessCode"
          autoFocus={!!emailField.value}
          placeholder="********"
          label="Access Code"
          error={!!authError}
          onFocus={dismissErrors}
          {...accessCodeFiled}
        />
      </div>
      <div className="mt-6 negative-margin">
        {authError && <ErrorBlock errorMessage={loginErrorMessage} />}
      </div>
      <div
        className={`${classNameSignInSubmitButton} flex justify-end mt-4 mb-4  btn-container`}
      >
        <div
          className="flex justify-center text-white font-size-13px font-medium text-forget cursor-pointer"
          onClick={props.showAccessCodeReminder}
        >
          <p onClick={signInWithDifferentEmail}>
            {props.showSubmitMessage
              ? `Sign in with a different email `
              : `Request Access Code`}
          </p>
        </div>
        <Button.Submit
          name="signIn"
          label="Sign in"
          size={"big"}
          disabled={!isValid || !!authError}
          className=" px-5 py-3 rounded bg-primary text-white capitalize"
        />
      </div>
    </Form>
  );
};
export default LoginForm;
