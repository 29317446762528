import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { HTTP_STATUS } from "../../../config/api";
import { NOT_FOUND_PATH } from "../../../config/routes/paths";
import { useEventPrivateInfo } from "../../../hooks/api/protected/use-event-private-details";
import useDefaultRoutePage from "../../../hooks/use-default-route-page";
import EventDetails from "../../shared/EventDetails";
import {
  IAuthContext,
  useAuth,
} from "../../../lib/context-providers/auth-context";
import bgImage from "../../../Assets/dashboard-bg-img.jpg";
import logoutBgImg from "../../../Assets/Hanging_Navigation.png";
import Icon from "../../../components/_base/Icon";
import {
  AGENDA_PATH,
  SESSION_PATH,
  SHOWCASE_PATH,
  SUPPORT_PATH,
} from "../../../config/routes/paths";
import KnovioThumbnail from "./KnovioThumbnail";
import mobileImg from "../../../Assets/mobile-welcome-img.jpg";
import MobileLayout from "../../shared/MobileLayout";
import card from "../../../Assets/welcome-card.png";
import scheduleIcon from "../../../Assets/mySchedule-Icon.svg";
import sessionsIcon from "../../../Assets/Sessions-Icon.svg";
import showcasesIcon from "../../../Assets/showcases-Icon.svg";
import helpDeskIcon from "../../../Assets/helpDesk-Icon.svg";
import { Button } from "../../../components/_base/Button";

// import MobileFooter from "../../shared/MobileFooter";
// import profileimg from "../../../Assets/Profile-Picture.png";
// import flagimg from "../../../Assets/Flag.png";
// import WelcomeBlock from "./WelcomeBlock";

const WelcomeWrapper: React.FC = (): JSX.Element | null => {
  const { data, isValidating, error } = useEventPrivateInfo();
  const landingPage = useDefaultRoutePage();
  const history = useHistory();
  const auth = useAuth() as IAuthContext;
  const className = "editable-welcome";

  const classMobileButton = `py-1 px-2 m-2 border bg-primary border-gray-400 font-bold text-white rounded`;

  const handleRoute = (path: string) => {
    history.push(path);
  };

  if (!isValidating && error?.status === HTTP_STATUS.NOT_FOUND) {
    return <Redirect to={NOT_FOUND_PATH} />;
  }

  if (!isValidating && data && !data.welcomeEnabled) {
    return <Redirect to={landingPage} />;
  }

  if (data)
    return (
      <>
        <div className={`${className} bg-img-wapper`}>
          <img src={bgImage} alt="bg-img" className="home-bgImage" />
          <div className="event-info">
            <EventDetails details={data} />
          </div>
          <div className="Left-nav-img">
            <img src={logoutBgImg} alt={"logout"} />
            <div
              onClick={() => auth.signOut()}
              className="fill-current text-white logout-wapper"
            >
              <Icon.LeftArrow width="30px" height="30px" className="mt-10px" />
              <span>logout</span>
            </div>
          </div>
          {/* <div className="right-nav-img">
            <img alt="user" src={logoutBgImg} /> 
            <div className="user-wapper">
              <img alt="profile" src={profileimg} className={`profile-img`} />
              <div>
                hi,
                <span className={`font-bold`}> Adam</span>
              </div>
            </div>
          </div> */}
          <div className="navigation-wrapper">
            <div className="each-link schedule-wapper md:w-1/6	">
              <div
                className="label-text text-sm	"
                onClick={() => handleRoute(AGENDA_PATH)}
              >
                <p>{data.agendaLabel}</p>
              </div>
              <div
                className="link-pulse"
                onClick={() => handleRoute(AGENDA_PATH)}
              ></div>
            </div>
            <div className="each-link case-wapper md:w-1/6">
              <div
                className="label-text text-sm	"
                onClick={() => handleRoute(SHOWCASE_PATH)}
              >
                <p>{data.showcaseLabel}</p>
              </div>
              <div
                className="link-pulse"
                onClick={() => handleRoute(SHOWCASE_PATH)}
              ></div>
            </div>
            <div className="each-link general-wapper md:w-1/6">
              <div
                className="link-pulse"
                onClick={() => handleRoute(SESSION_PATH)}
              ></div>
              <div
                className="label-text text-sm	"
                onClick={() => handleRoute(SESSION_PATH)}
              >
                <p>{data.sessionLabel}</p>
              </div>
            </div>
            <div className="each-link content-wapper md:w-1/6">
              {/* <div className="link-pulse"></div> */}
              <div className="label-text text-sm	">
                {/* <p>content library </p> */}
              </div>
            </div>
            <div className="each-link help-wapper md:w-1/6">
              <div className={`flex justify-center`}>
                <div
                  className="link-pulse help-link-pulse"
                  onClick={() => handleRoute(SUPPORT_PATH)}
                ></div>
              </div>
              <div
                className="label-text text-sm "
                onClick={() => handleRoute(SUPPORT_PATH)}
              >
                <p>{data.supportLabel}</p>
              </div>
            </div>
          </div>
          <div className="center-img">
            <img alt="main logi" src={data.logoUrl} />
          </div>
          <div>
            {data.welcomeKnovioEnabled && data.welcomeKnovioEmbedId && (
              <KnovioThumbnail
                welcomeKnovioEmbedId={data.welcomeKnovioEmbedId}
              />
            )}
          </div>
        </div>
        <div className={`mobile-container`}>
          <img
            src={mobileImg}
            alt={`mobile-welcome-img`}
            className={`mobile-img-bg`}
          />
          <div className={`mobile-layout overflow-y-scroll`}>
            <MobileLayout />
            {!auth.showSideMenu && (
              <>
                <div className={`px-6 py-4`}>
                  <h1 className="font-size-24px text-white">Welcome</h1>
                  <div className={`relative my-4  `}>
                    <img
                      src={card}
                      alt="meeting-info"
                      className={`card-shadow`}
                    />
                    <div className={`absolute card-container w-full h-full`}>
                      <div className={`w-4/6`}>
                        <img
                          src={data.logoUrl}
                          alt={data.logoTitle}
                          title={data.logoTitle}
                        />
                      </div>
                      <div className={`w-4/6 mt-8 break-words`}>
                        <p className={`text-white opacity-70`}>{data.name}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={`flex justify-between	items-center  `}>
                      <div className={`my-2 flex w-3/5	 items-center	`}>
                        <img src={scheduleIcon} alt="" className={`m-2`} />
                        <p className={`text-white font-size-16px px-4`}>
                          {data.agendaLabel}
                        </p>
                      </div>
                      <Button
                        name="meetingItem"
                        type="button"
                        label={"View All"}
                        className={`${classMobileButton}`}
                        onClick={() => handleRoute(AGENDA_PATH)}
                      />
                    </div>
                    <hr className={`m-2	`} />
                    <div className={`flex justify-between	items-center `}>
                      <div className={`my-2 flex w-3/5	 items-center	`}>
                        <img src={sessionsIcon} alt="" className={`m-2`} />
                        <p className={`text-white font-size-16px px-4`}>
                          {data.sessionLabel}
                        </p>
                      </div>
                      <Button
                        name="meetingItem"
                        type="button"
                        label={"View All"}
                        className={`${classMobileButton}`}
                        onClick={() => handleRoute(SESSION_PATH)}
                      />
                    </div>
                    <hr className={`m-2	`} />
                    <div className={`flex justify-between	items-center `}>
                      <div className={`my-2 flex w-3/5	 items-center	`}>
                        <img src={showcasesIcon} alt="" className={`m-2`} />
                        <p className={`text-white font-size-16px px-4`}>
                          {data.showcaseLabel}
                        </p>
                      </div>
                      <Button
                        name="meetingItem"
                        type="button"
                        label={"View All"}
                        className={`${classMobileButton}`}
                        onClick={() => handleRoute(SHOWCASE_PATH)}
                      />
                    </div>
                    <hr className={`m-2	`} />
                    <div className={`flex justify-between	items-center `}>
                      <div className={`my-2 flex w-3/5	 items-center	`}>
                        <img src={helpDeskIcon} alt="" className={`m-2`} />
                        <p className={`text-white font-size-16px px-4`}>
                          {data.supportLabel}
                        </p>
                      </div>
                      <Button
                        name="meetingItem"
                        type="button"
                        label={"View All"}
                        className={`${classMobileButton}`}
                        onClick={() => handleRoute(SUPPORT_PATH)}
                      />
                    </div>
                  </div>
                  {/* <div className={`absolute w-full m-footer left-0	text-center`}>
                    <MobileFooter />
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );

  return null;
};

export default WelcomeWrapper;
