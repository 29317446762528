import React from "react";
import { useHistory } from "react-router";
import { REGISTRATION_PATH } from "../../../../config/routes/paths";
import Button from "../../../../components/button";

const RegisterBlock: React.FC = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className="flex justify-start font-size-13px items-center">
      <span className="text-white opacity-70">Not registered yet?</span>
      <Button.Common
        size={"auto"}
        name="Register Now"
        onClick={() => history.push(REGISTRATION_PATH)}
        label="Register Now"
        className="text-white ml-4 cursor-pointer underline"
      />
    </div>
  );
};

export default RegisterBlock;
